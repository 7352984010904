import { render, staticRenderFns } from "./HomeLayoutECO.vue?vue&type=template&id=975f3f34&scoped=true&"
import script from "./HomeLayoutECO.vue?vue&type=script&lang=js&"
export * from "./HomeLayoutECO.vue?vue&type=script&lang=js&"
import style0 from "./HomeLayoutECO.vue?vue&type=style&index=0&id=975f3f34&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "975f3f34",
  null
  
)

export default component.exports